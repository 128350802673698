import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Container from "./Container"
import { StaticImage } from "gatsby-plugin-image"

const navItems = [
  { id: 1, title: "Strona główna", link: "/" },
  { id: 2, title: "O nas", link: "/o-nas/" },
  { id: 3, title: "Dokumenty", link: "/dokumenty/" },
  { id: 4, title: "Aktualności", link: "/aktualnosci/" },
  { id: 5, title: "Kontakt", link: "/kontakt/" },
]

const Header = () => {
  const [isMenuOpen, setIsMenuyOpen] = useState(false)

  useEffect(() => {
    const closeMenu = e => {
      const clickedOverTopbar = !!!e.target.closest('#topbar')

      if(clickedOverTopbar) return setIsMenuyOpen(false)
    }

     window.addEventListener('click', closeMenu)

    return !isMenuOpen && window.removeEventListener('click', closeMenu)

  }, [isMenuOpen])

  return (
    <Container as="nav" classes="topbar" id='topbar'>
      <Link to="/" className="topbar__logo">
        <StaticImage src="../assets/images/logo.png" alt="logo" />
      </Link>
      <button
        className="topbar__nav-btn"
        onClick={() => setIsMenuyOpen(!isMenuOpen)}
      >
        <span className="nav-btn__inner"></span>
        <span className="nav-btn__inner"></span>
        <span className="nav-btn__inner"></span>
      </button>
      <ul className="topbar__nav" data-open={isMenuOpen}>
        {navItems.map(nav => (
          <li key={nav.id} className="nav__item">
            <Link to={nav.link} className="nav__link" onClick={() => setIsMenuyOpen(false)}>
              {nav.title}
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default Header
