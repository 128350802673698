exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktualnosci-index-jsx": () => import("./../../../src/pages/aktualnosci/index.jsx" /* webpackChunkName: "component---src-pages-aktualnosci-index-jsx" */),
  "component---src-pages-aktualnosci-jsx": () => import("./../../../src/pages/aktualnosci/[...].jsx" /* webpackChunkName: "component---src-pages-aktualnosci-jsx" */),
  "component---src-pages-dokumenty-jsx": () => import("./../../../src/pages/dokumenty.jsx" /* webpackChunkName: "component---src-pages-dokumenty-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-nas-jsx": () => import("./../../../src/pages/o-nas.jsx" /* webpackChunkName: "component---src-pages-o-nas-jsx" */)
}

