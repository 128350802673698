import React from "react"

const Title = props => {
  const { children, as: Tag, size, fontFamily, color = "dark", classes } = props

  return (
    <Tag
      data-size={size}
      data-font={fontFamily}
      data-color={color}
      className={`title${classes ? " " + classes : ""}`}
    >
      {children}
    </Tag>
  )
}

export default Title
