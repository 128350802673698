import React from "react"
import { Link } from "gatsby"

import Container from "./Container"
import Title from "./Title"
import Text from "./Text"

const Footer = () => {
  return (
    <Container as="footer" classes="footer" fluid>
      <div className="footer__container">
        <div className="footer__group">
          <Title
            classes="footer__title"
            as="h5"
            size="4"
            color="light"
            fontFamily="serif"
          >
            Nawigacja
          </Title>
          <Link to="/" className="footer__link">
            <Text as="span" color="light">
              Strona główna
            </Text>
          </Link>
          <Link to="/#dokumenty" className="footer__link">
            <Text as="span" color="light">
              Dokumenty
            </Text>
          </Link>
          <Link to="/o-nas" className="footer__link">
            <Text as="span" color="light">
              O nas
            </Text>
          </Link>
          <Link to="/o-nas/#patron" className="footer__link">
            <Text as="span" color="light">
              O patronie
            </Text>
          </Link>
          <Link to="/aktualnosci" className="footer__link">
            <Text as="span" color="light">
              Aktualności
            </Text>
          </Link>
        </div>
        <div className="footer__group">
          <Title
            classes="footer__title"
            as="h5"
            size="4"
            color="light"
            fontFamily="serif"
          >
            Social Media
          </Title>
          <a
            href="https://www.facebook.com/SPLwowek/?locale=pl_PL"
            rel="noopener"
            target="_blank"
            className="footer__link"
          >
            <Text as="span" color="light">
              Facebook
            </Text>
          </a>
        </div>
        <div className="footer__group">
          <Title
            classes="footer__title"
            as="h5"
            size="4"
            color="light"
            fontFamily="serif"
          >
            Sekretariat
          </Title>
          <Text as="span" color="light">
            Czynny w godz.: 7:30 - 15:30
          </Text>
        </div>
        <div className="footer__group">
          <Title
            classes="footer__title"
            as="h5"
            size="4"
            color="light"
            fontFamily="serif"
          >
            <Link to="/kontakt">Kontakt</Link>
          </Title>
          <a href="tel:+48661133316" className="footer__link">
            <Text as="span" color="light">
              Sekretariat: +48 661 133 316
            </Text>
          </a>
          <a href="tel:+48518383217" className="footer__link">
            <Text as="span" color="light">
              Świetlica: +48 518 383 217
            </Text>
          </a>
          <a href="tel:+48665133990" className="footer__link">
            <Text as="span" color="light">
              Portiernia: +48 665 133 990
            </Text>
          </a>
          <a href="mailto:splwowek@interia.pl" className="footer__link">
            <Text as="span" color="light">
              E-mail: splwowek@interia.pl
            </Text>
          </a>
          <hr />
          <a
            href="https://goo.gl/maps/5bvE6GpkNNGzwWZD8"
            className="footer__link"
          >
            <Text as="span" color="light">
              64-310 Lwówek
              <br />
              ul. Gimnazjalna 1
            </Text>
          </a>
        </div>
      </div>
      <Text classes="footer__copy" color="light">
        <small>
          All rights reserved &copy;Copywright 2023{" "}
          <a href="https://www.websiteab.com/" target="_blank" rel="noreferrer">
             WebsiteAB - Adrian Baran
          </a>
        </small>
      </Text>
    </Container>
  )
}

export default Footer
