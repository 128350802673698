import React from "react"

const Container = (props) => {
  const { as: Tag, fluid, classes, children , id} = props

  return (
    <Tag className={classes} id={id}>
      <div className="container" data-type={fluid ? "fluid" : "default"}>
        {children}
      </div>
    </Tag>
  )
}

export default Container
